<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input v-model="searchForm.name" placeholder="标题" clearable>
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="getBanner">搜索</el-button>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="
            dialogVisible = true;
            fileList = [];
          "
          >新增</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-popconfirm title="确定批量删除吗？" @confirm="delHandle(null)">
          <el-button type="danger" slot="reference" :disabled="delBtlStatus"
            >批量删除</el-button
          >
        </el-popconfirm>
      </el-form-item>
    </el-form>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      border
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>

      <el-table-column prop="id" label="编码" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="title" label="标题" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="url" label="URL" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="note" label="描述" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="updated" label="更新时间" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="created" label="创建时间" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="statu" label="状态">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.statu === 1" type="success"
            >正常</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.statu === 0" type="danger"
            >禁用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="icon" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="editHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-divider direction="vertical"></el-divider>

          <template>
            <el-popconfirm
              title="确定删除吗？"
              @confirm="delHandle(scope.row.id)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :current-page="current"
      :page-size="size"
      :total="total"
    >
    </el-pagination>

    <!--新增对话框-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="100px"
        class="demo-editForm"
      >
        <el-form-item label="编码" v-if="false" prop="id" label-width="100px">
          <el-input v-model="editForm.id"></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title" label-width="100px">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="URL" prop="url" label-width="100px">
          <el-input v-model="editForm.url"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="note" label-width="100px">
          <el-input v-model="editForm.note"></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="statu" label-width="100px">
          <el-radio-group v-model="editForm.statu">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">正常</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传图片" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-upload
                action
                ref="upload"
                :before-remove="beforeRemove"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList"
                :before-upload="beforeAvatarUpload"
                :http-request="handlerUpload"
              >
                <el-button size="small" icon="el-icon-document" type="primary"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件, 且不超过1MB
                </div>
              </el-upload></el-col
            >
            <el-col :span="12">
              <img
                :src="
                  editForm.urlimg
                    ? apiUrl.split(':')[0] +
                      ':' +
                      apiUrl.split(':')[1] +
                      '/' +
                      editForm.urlimg
                    : ''
                "
                class="avatar"
            /></el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="图片地址" prop="urlimg" label-width="100px">
          <el-input v-model="editForm.urlimg" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('editForm')"
            >立即创建</el-button
          >
          <el-button @click="resetForm('editForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      searchForm: {},
      delBtlStatus: true,

      total: 0,
      size: 10,
      current: 1,

      dialogVisible: false,
      editForm: {},

      tableData: [],

      editFormRules: {
        // url: [{ required: true, message: "请输入URL", trigger: "blur" }],
        statu: [{ required: true, message: "请选择状态", trigger: "change" }],
        urlimg: [{ required: true, message: "请上传图片", trigger: "blur" }],
      },

      multipleSelection: [],

      defaultProps: {
        children: "children",
        label: "name",
      },
      fileList: [],
      oldUrlImg: "",
      apiUrl: "",
    };
  },
  created() {
    this.getBanner();
    this.apiUrl = this.$axios.defaults.baseURL;
  },
  methods: {
    // toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach((row) => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    handleSelectionChange(val) {
      this.multipleSelection = val;

      this.delBtlStatus = val.length == 0;
    },

    handleSizeChange(val) {
      this.size = val;
      this.getBanner();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getBanner();
    },

    resetForm(formName) {
      if (this.oldUrlImg && this.oldUrlImg != this.editForm.urlimg) {
        this.clearFile(this.editForm.urlimg);
        this.oldUrlImg = "";
      }
      if (!this.editForm.id && this.editForm.urlimg) {
        this.clearFile(this.editForm.urlimg);
      }
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {};
    },
    handleClose() {
      this.resetForm("editForm");
    },

    getBanner() {
      this.$axios
        .get("/sys-banner/list", {
          params: {
            name: this.searchForm.name,
            current: this.current,
            size: this.size,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.records;
          this.size = res.data.data.size;
          this.current = res.data.data.current;
          this.total = res.data.data.total;
        });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post(
              "/sys-banner/" + (this.editForm.id ? "update" : "save"),
              this.editForm
            )
            .then((res) => {
              this.$message({
                showClose: true,
                message: "恭喜你，操作成功",
                type: "success",
                onClose: () => {
                  this.getBanner();
                },
              });
              if (this.oldUrlImg && this.oldUrlImg != this.editForm.urlimg) {
                this.clearFile(this.oldUrlImg);
                this.oldUrlImg = "";
              }
              this.$refs[formName].resetFields();
              this.dialogVisible = false;
              this.editForm = {};
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get("/sys-banner/info/" + id).then((res) => {
        if (res.data.data) {
          this.editForm = res.data.data;

          this.fileList = [];
          this.dialogVisible = true;
        } else {
          this.$message({
            showClose: true,
            message: "查询数据异常",
            type: "error",
            onClose: () => {
              this.getBanner();
            },
          });
        }
      });
    },
    delHandle(id) {
      var ids = [];

      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach((row) => {
          ids.push(row.id);
        });
      }

      this.$axios.post("/sys-banner/delete", ids).then((res) => {
        this.$message({
          showClose: true,
          message: "恭喜你，操作成功",
          type: "success",
          onClose: () => {
            this.getBanner();
          },
        });
      });
    },

    //上传图片配置
    beforeAvatarUpload(file) {
      const isType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isType) {
        this.$message.error("上传图片只能是 JPG 或者 PNG 格式！");
      }
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }

      return isType && isLt1M;
    },
    handleExceed(files) {
      this.$message.warning(`每次只能选择1个文件`);
    },
    beforeRemove(file) {
      console.log("file", file);
      if (
        (file.raw.type === "image/jpeg" || file.raw.type === "image/png") &&
        file.size / 1024 / 1024 < 1
      ) {
        return new Promise((reslove, reject) => {
          this.$confirm(`确定移除 ${file.name}?`).then(() => {
            this.$axios
              .post(
                "/fileoss/delOssFile?filename=" +
                  [
                    this.editForm.urlimg.split("/")[
                      this.editForm.urlimg.split("/").length - 1
                    ],
                  ],
                {}
              )
              .then((res) => {
                if (res.data.code == 200) {
                  this.editForm.urlimg = "";
                  this.$forceUpdate();
                  reslove();
                } else {
                  reject();
                }
              })
              .catch((err) => {
                reject();
              });
          });
        });
      }
    },
    handlerUpload(param) {
      let fileObject = param.file;
      let formData = new FormData();
      formData.append("file", fileObject);
      this.$axios.post("/fileoss/uploadOssFile", formData).then((res) => {
        if (res.data.data.startsWith("OK")) {
          if (this.editForm.urlimg) {
            this.oldUrlImg = this.editForm.urlimg;
          }
          this.editForm.urlimg = res.data.data.split(":")[1];
          //TODO 文件服务器地址 获取方式未确定

          this.$forceUpdate();
        }
      });
    },
    clearFile(urlimg) {
      if (urlimg) {
        this.$axios
          .post(
            "/fileoss/delOssFile?filename=" +
              urlimg.split("/")[urlimg.split("/").length - 1]
          )
          .then((res) => {});
      }
    },
  },
};
</script>

<style scoped>
.el-pagination {
  float: right;
  margin-top: 22px;
}
.avatar {
  width: 208px;
  height: 128px;
}
.el-upload {
  text-align: left;
  width: 100%;
}
</style>
